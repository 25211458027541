<template>
  <div>
    <el-dialog title="新增/修改" :visible.sync="dialogVisible1" width="500px" :before-close="handleClose1">
      <el-form :model="formData1" ref="formData1" :rules="rules1" label-width="180px">
        <el-form-item label="测点编号" prop="code">
          <el-input v-model="formData1.code" @blur="onInputBlur" placeholder="请输入测点编号" />
        </el-form-item>
        <el-form-item label="测点名称">
          <el-input v-model="formData1.name" placeholder="" />
        </el-form-item>
        <el-form-item label="测点位置">
          <el-input v-model="formData1.site" placeholder="" />
        </el-form-item>
        <el-form-item label="监测指标" prop="type">
          <el-select v-model="formData1.type" placeholder="请选择" @change="getChange" :disabled="typeDis">
            <el-option v-for="item in dataList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="X坐标值" :prop="selX == true ? 'x' : 'm'">
          <el-input v-model="formData1.x" placeholder="" :disabled="dis" />
        </el-form-item>
        <el-form-item label="Y坐标值" :prop="selX == true ? 'y' : 'm'">
          <el-input v-model="formData1.y" placeholder="" :disabled="dis" />
        </el-form-item>
        <el-form-item label="Z坐标值" :prop="selZ == true ? 'z' : 'm'">
          <el-input v-model="formData1.z" placeholder="" />
        </el-form-item>
        <el-form-item label="测量时间" prop="time">
          <el-date-picker @change="timeChange" placeholder="请选择时间" type="datetime" :clearable="false"
            format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="formData1.time">
          </el-date-picker>
        </el-form-item>


        <el-form-item class="btns">
          <el-button class="back-btn pointer" @click="back1">取消</el-button>
          <el-button class="submit-btn mR30 pointer" @click="submitForm1">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="180px">
        <el-form-item label="文件附件" prop="file_arr">
          <el-upload action=" " multiple :limit="5" :file-list="fileListAdd" :on-remove="handleAddRemove"
            :on-change="handleAddChange" :on-exceed="handleExceed" :auto-upload="false">
            <el-button v-show="!hideUploadAdd" size="small" type="primary" class="select-btn">选择</el-button>
            <span slot="tip" class="el-upload__tip">上传文件不超过50M</span>
          </el-upload>
        </el-form-item>
        <el-form-item class="btns">
          <el-button class="back-btn pointer" @click="back">取消</el-button>
          <el-button class="submit-btn mR30 pointer" @click="submitForm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>




    <el-form class="el-form-custom" :model="formData" :inline="true">
      <el-form-item label="">
        <el-button :class="disabled ? 'disable_icon' : ''" @click="add(1)">新增</el-button>
        <!-- <el-button @click="ImportData()">导入</el-button> -->
      </el-form-item>

      <!--
      <el-form-item label="自选状态">
        <el-select v-model="formData.selectedRel" placeholder="请选择">
          <el-option
            v-for="item in statusOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"/>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item>
        <el-input v-model="formData.code" placeholder="请输入测点编号"/>
      </el-form-item> -->


      <el-form-item style="float: right">
        <el-input v-model="formData.code" placeholder="请输入测点编号" />
        <el-button @click="getData('query')">查询</el-button>
        <!-- <el-button @click="handlePoint" :class="disabled?'disable_icon':''">添加测点</el-button>
        <el-button @click="resetData">
          <svg class="icon reset-icon" aria-hidden="true">
            <use xlink:href="#iconlujing"></use>
          </svg>
          <span>重置</span>
        </el-button> -->
      </el-form-item>
      <el-form-item label="监测指标" style="float: right">
        <el-select v-model="formData.typeId" placeholder="请选择" @change="changeQuota">
          <el-option v-for="item in quotaSelect" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" :row-class-name="tableRowClassName" style="width: 100%;">
      <el-table-column align="center" label="测点编号" prop="code" min-width="180px"></el-table-column>
      <el-table-column align="center" label="测点名称" prop="name"></el-table-column>
      <el-table-column align="center" label="测点位置" prop="site"></el-table-column>
      <el-table-column align="center" label="X坐标值" prop="x"></el-table-column>
      <el-table-column align="center" label="△X" prop="deltaX"></el-table-column>
      <el-table-column align="center" label="Y坐标值" prop="y"></el-table-column>
      <el-table-column align="center" label="△Y" prop="deltaY"></el-table-column>
      <el-table-column align="center" label="Z坐标值" prop="z"></el-table-column>
      <el-table-column align="center" label="△Z" prop="deltaZ"></el-table-column>
      <el-table-column align="center" label="测量时间" prop="measureTime" width="230"></el-table-column>
      <el-table-column align="center" label="操作" width="180">
        <template slot-scope="scope">
          <!-- <svg class="icon view-icon" aria-hidden="true" @click="showDetail(scope.row.id)">
            <use xlink:href="#iconchakan"/>
          </svg> -->
          <!-- <svg class="icon" :class="disabled ? 'disable_icon' : ''" aria-hidden="true" @click="handleEdit(scope.row, 2)">
            <use xlink:href="#iconbianji" />
          </svg>
          <svg class="icon" :class="disabled ? 'disable_icon' : ''" aria-hidden="true" @click="handleDel(scope.row.dataId)">
            <use xlink:href="#iconshanchu" />
          </svg> -->
          <span class="btn-wrapper">
            <span type="text" class="colorText pointer" size="small" @click="showDetail(scope.row.id)">查看</span>
            <span type="text" class="colorText pointer" size="small" @click="handleEdit(scope.row.id)">编辑</span>
            <span type="text" class="colorText pointer" size="small" @click="handleDel(scope.row.id)">删除</span>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <common-pagination :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
  </div>
</template>

<script>
import CommonPagination from '@/components/Pagination'
export default {
  components: {
    CommonPagination
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      disabled: false,
      formData: { typeGroupId: '', typeId: '' },
      typeOptions: [],
      targetOptions: [],
      statusOptions: [
        { id: 1, name: '是' },
        { id: 0, name: '否' }
      ],
      tableData: [],
      // 测量值管理
      dialogVisible: false,
      ruleForm: { title: '', file_arr: [] },
      rules: {
        title: [
          { required: true, message: '请输入文件名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        file_arr: [
          { type: 'array', required: true, message: '请选择文件', trigger: 'blur' }
        ]
      },
      dis: false,
      fileListAdd: [],

      addEdit: 1,
      dataId: '',   //监测数据id
      targetId: '', // 测点id
      // 弹框
      dataList: [{ 'id': '1', 'name': '位移' }, { 'id': '2', 'name': '沉降' }],
      dialogVisible1: false,
      formData1: {
        'code': '',
        'name': '',
        'site': '',
        'type': '',
        'x': '',
        'y': '',
        'z': '',
        'time': '',
      },
      rules1: {
        code: [
          { required: true, message: '请输入编号', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        x: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        y: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        z: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        m: [
          { required: false, message: '请输入', trigger: 'blur' },
        ],
        time: [  // type: 'date',
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
      },

      typeDis: false,   //添加相同的测点编号，  指标不能改

      selX: '',
      selZ: '',

      quotaSelect: [
        { id: '', name: '全部' }, { id: '1', name: '位移' }, { id: '2', name: '沉降' },
      ],


    }
  },
  mounted() {
    // 官网体验版isAdmin不为1时禁用，正式版为1.isTrue是true时，不禁用。
    // if(sessionStorage.getItem('isAdmin') == 1) {
    this.disabled = sessionStorage.getItem('isTrue') == 'true' ? false : true
    // } else {
    // this.disabled = true
    // }
    this.getPointTypes()
    this.getData()
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {       //条纹变色
      if (rowIndex % 2 == 1) {
        return 'hui1';
      } else {
        return 'lv1';
      }
    },
    // 切换指标
    changeQuota() {
      // this.getData();
    },
    inputChange(e) {
      console.log(e)
      this.$forceUpdate();
    },
    inputChange1(e) {
      console.log(e)
      this.$forceUpdate();
    },

    // 日期change
    timeChange() { },
    // 失去焦点获取
    onInputBlur() {
      this.$axios.get(`${this.baseURL}offline/target/info?code=${this.formData1.code}`).then(res => {
        console.log('失去焦点获取信息', res);
        var data = res.data.data;
        console.log('data', data);
        if (data.length == 0) {
          // this.formData1.type ='';
          this.addEdit = 1;
          this.dis = false;
          this.typeDis = false;
          return;
        };
        console.log('11111111111');

        // this.formData1.name = data.name;
        // this.formData1.site = data.site;
        this.formData1.type = (data.quota).toString();
        if (this.formData1.type == 1) {
          this.selX = true;
          this.selZ = true;
        } else {
          this.selZ = true;
          this.selX = false;
        }
        this.formData1.name = data.name;
        this.formData1.site = data.site;

        this.targetId = data.id;
        this.typeDis = true;
        this.addEdit = 3;
        console.log('from', this.formData1);
        if (data.quota == 2) {
          this.dis = true;
        }

      });
    },
    // 导入
    ImportData() {
      this.dialogVisible = true;
    },
    // 添加  显示窗口
    add() {
      if (this.disabled) returne
      this.formData1.code = '';
      this.dis = false;
      this.typeDis = false;
      this.addEdit = 1;
      this.dialogVisible1 = true;

      this.$nextTick(() => {
        // this.$refs.formData1.resetFields();//等弹窗里的form表单的dom渲染完在执行this.$refs.staffForm.resetFields()，去除验证
      });
      this.$nextTick(() => {
        this.formData1 = JSON.parse(JSON.stringify(this.formData1));    //重置编辑
        // this.$refs.formData1.resetFields();//等弹窗里的form表单的dom渲染完在执行this.$refs.staffForm.resetFields()，去除验证
      });
    },
    getChange() {
      console.log('11', this.formData1.type);
      if (this.formData1.type == 1) {
        this.selX = true;
        this.selZ = true;
        this.dis = false;
      }
      if (this.formData1.type == 2) {
        this.selX = false;
        this.selZ = true;
        this.dis = true;
      }
    },
    handleClose1() {
      this.selX = false;
      this.selZ = false;
      this.$refs.formData1.resetFields();   //清空验证
      // this.$refs.formData1.clearValidate();   //清空验证/
      this.formData1 = Object.assign({}, '')
      this.dialogVisible1 = false;
    },
    // 新增测点
    submitForm1() {
      //  console.log('type', this.formData1.type);
      //  console.log('time', this.formData1.time);
      //  this.formData1.type = '1';
      //  this.formData1.time ='2021-09-26 16:08:00'
      //  console.log('this.$store.state.projectId', this.$store.state.projectId);
      //  return;
      //  this.formData1.type = (this.formData1.type).toString();

      //  return;
      var time = this.formData1.time + ':00'
      this.$refs.formData1.validate(valid => {
        if (valid) {
          if (this.addEdit == 1) {     //添加
            var parmas = {
              'code': this.formData1.code,
              'measureTime': time,
              'name': this.formData1.name,
              'projectId': this.$store.state.projectId,
              'quota': this.formData1.type,
              'site': this.formData1.site,
              'x': this.formData1.x,
              'y': this.formData1.y,
              'z': this.formData1.z,
            }
          } else if (this.addEdit == 2) {    //编辑
            var parmas = {
              'dataId': this.dataId,
              'targetId': this.targetId,
              'code': this.formData1.code,
              'measureTime': time,
              'name': this.formData1.name,
              'projectId': this.$store.state.projectId,
              'quota': this.formData1.type,
              'site': this.formData1.site,
              'x': this.formData1.x,
              'y': this.formData1.y,
              'z': this.formData1.z,
            }
          } else {
            var parmas = {
              'targetId': this.targetId,
              'code': this.formData1.code,
              'measureTime': time,
              'name': this.formData1.name,
              'projectId': this.$store.state.projectId,
              'quota': this.formData1.type,
              'site': this.formData1.site,
              'x': this.formData1.x,
              'y': this.formData1.y,
              'z': this.formData1.z,
            }
          }

          console.log('parmas', parmas);


          this.$axios.post(`${this.baseURL}offline/save`, parmas).then(res => {
            console.log('提交添加', res);
            // this.loadingPage.close()
            this.getData();
            this.handleClose1()
          });


        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //返回
    back1() {
      this.handleClose1();
    },



    //返回
    back() {
      this.handleClose();
    },
    // 确定新增
    submitForm() {
      this.ruleForm.file_arr = this.fileListAdd
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loadingPage = this.$loading({
            lock: true,
            text: '正在加载...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          let formData = new FormData();
          formData.append('title', this.ruleForm.title)
          this.fileListAdd.map(item => {
            formData.append("file", item.raw);
          });
          this.$axios.post(`${this.baseURL}sys/sysFile/project/import?bizId=${this.$store.state.projectId}&originalName=${this.ruleForm.title}`, formData).then(res => {
            this.$message({
              message: '上传成功',
              type: "success"
            });
            this.loadingPage.close()
            this.handleClose()
          });
        }
      });
    },
    handleClose() {
      // this.$nextTick(() => {
      //   this.$refs.ruleForm.resetFields()
      //   this.fileListAdd = [];
      // })
      this.dialogVisible1 = false;
      this.getData();
      // this.datalList();
    },
    hideUploadAdd() { },

    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 50;
      if (!isLt20M) {
        this.$message.error("文件太大了!");
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
        this.ruleForm.file_arr = this.fileListAdd
        this.$refs.ruleForm.validate(valid => { })
      }
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 5) {
        this.hideUploadAdd = true;
      }
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.hideUploadAdd = false;
      this.handleAddChange(file, fileList)
    },

    getPointTypes() {
      this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then(res => {
        res = res.data
        if (res.errCode == 200) {
          res.data.unshift({ id: '', name: "全部" })
          this.typeOptions = res.data
          this.getTargetOptions()
        }
      })
    },
    getTargetOptions() {
      this.$axios.get(`${this.baseURL}base/baseTargetType/byGroupId`, {
        typeGroupId: this.formData.typeGroupId
      }).then(res => {
        res = res.data
        if (res.errCode == 200) {
          res.data.unshift({ id: '', name: "全部" })
          this.targetOptions = res.data
        }
      })
    },

    //列表--- zl
    getData(val) {
      if (val) this.currentPage = 1
      var params = {
        "code": this.formData.code,
        "pageNumber": this.currentPage,
        "pageSize": 10,
        "quota": this.formData.typeId
      };
      // {params: params}
      this.$axios.post(`${this.baseURL}offline/page`, params).then(res => {
        console.log('列表', res);

        res = res.data
        if (res.errCode == 200) {
          this.total = res.data.total
          this.tableData = res.data.records
        }
      })
    },
    resetData() {
      this.formData = { typeGroupId: '', typeId: '' }
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData()
    },
    changeStatus(val) {
      if (this.total % 10 == 1) this.currentPage -= 1
      if (val.selectedRel == 1) {
        // 设为自选
        this.$axios.post(`${this.baseURL}base/baseTargetSelectedRel/${this.$store.state.projectId}/${val.id}`).then(res => {
          res = res.data
          if (res.errCode == 200) this.getData()
        })
      } else {
        // 取消自选
        this.$axios.delete(`${this.baseURL}base/baseTargetSelectedRel/${val.id}`).then(res => {
          res = res.data
          if (res.errCode == 200) this.getData()
        })
      }
    },
    handlePoint() {
      if (this.disabled) return
      this.$router.push('/handlePoint')
    },
    showDetail(id) {
      this.$router.push('/viewPoint/' + id)
    },
    handleEdit(item, val) {
      console.log('item', item);
      if (this.disabled) return


      if (item.quota == 1) {
        this.selX = true;
        this.selZ = true;
      } else {
        this.selZ = true;
        this.selX = false;
      }

      this.dataId = item.dataId; //编辑时
      this.addEdit = 2;   //1add 2edit
      this.targetId = item.targetId;  //测点id

      this.dialogVisible1 = true;
      this.formData1.code = item.code;
      // 'measureTime': time,
      this.formData1.name = item.name;
      // 'projectId': this.$store.state.projectId,
      this.formData1.site = item.site;
      this.formData1.x = item.x;
      this.formData1.y = item.y;
      this.formData1.z = item.z;
      this.formData1.time = item.measureTime;
      this.formData1.type = (item.quota).toString();

      console.log('1', this.formData1.code)
      this.$nextTick(() => {
        this.formData1 = JSON.parse(JSON.stringify(this.formData1));    //重置编辑
        // this.$refs.formData1.resetFields();//等弹窗里的form表单的dom渲染完在执行this.$refs.staffForm.resetFields()，去除验证
      });

      // this.$router.push({
      //   path: '/handlePoint',
      //   query: {
      //     id,
      //     type:'edit'
      //   }
      // })
    },
    handleDel(id) {
      if (this.disabled) return
      this.$confirm('确认删除该测点？', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        cancelButtonClass: 'btn-cancel',
        confirmButtonClass: 'btn-confirm'
      }).then(() => {
        var del = id;
        this.$axios.get(`${this.baseURL}offline/del/${del}`,).then(res => {
          console.log('删除', res);
          res = res.data
          if (res.errCode == 200) this.getData()
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
td .icon {
  font-size: 22px;
  cursor: pointer;
}

td .view-icon {
  font-size: 24px;
  color: rgb(134, 232, 255);

  &:hover {
    color: #3997AD;
  }
}

.icon:nth-child(2) {
  margin: 0 20px;
  color: #DBF804;

  &:hover {
    color: #98B300;
  }
}

.icon:last-child {
  color: #F7F7F7;

  &:hover {
    color: #c3c3c3;
  }
}

.el-table {
  margin-bottom: 35px;
}

.finish {
  color: #86E8FF;
}

.el-form-item {
  margin-bottom: 15px;
}

::v-deep .hui1 {
  background: #091D46 !important;
}

::v-deep .lv1 {
  background: #0C265A !important;
}



.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;

}

.btn-wrapper span {
  flex: 1;
  text-align: center;
}
</style>
